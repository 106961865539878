import { ReactComponent as EnvelopeIcon } from "../lib/icons/Envelope.svg";
import { ReactComponent as HomeIcon } from "../lib/icons/Home.svg";
import { ReactComponent as PhoneIcon } from "../lib/icons/Phone.svg";

export const conInfo2 = [
  {
    conIcon: <HomeIcon className="h-[3.4375rem] fill-white"></HomeIcon>,
    conTitle: "Mr Shaurav",
    conCap: "",
  },
  {
    conIcon: <PhoneIcon className="h-[3.4375rem] fill-white"></PhoneIcon>,
    conTitle: "Phone:",
    conCap: "+88 0171 298 4115",
  },
  {
    conIcon: <EnvelopeIcon className="h-[3.4375rem] fill-white"></EnvelopeIcon>,
    conTitle: "Email Address:",
    conCap: "shauravaub1985@gmail.com",
  },
];
