import React, { Fragment } from "react";

import { AboutUs } from "../../components/AboutUs";
import { BlogPost } from "../../components/BlogPosts";
import { BottomBar } from "../../components/BottomBar";
import Carousel9 from "../../components/Carousel9";
import { ContactInfoProfiles } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { FeaturedArea } from "../../components/FeaturedArea";
import { FreelanceWork } from "../../components/FreelanceWork";
import { Header } from "../../components/Header";
import { Helmet } from "react-helmet";
import HomePosters from "./HomePosters";
import ImageGallery from "./ImageGallery";
import { Link } from "react-router-dom";
import { MyWorkExperience } from "../../components/MyWorkExperience";
import { Partners } from "../../components/Partners";
import PatternImg from "../../assets/images/patternImg4.png";
import { Portfolio } from "../../components/Portfolio";
import Published from "../../components/Published";
import Recommendation from "../../components/Recommendation";
import { SectionTitle } from "../../components/SectionTitles";
import { Testimonials } from "../../components/Testimonials";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Home - Resmi Mirza</title>
      </Helmet>
      <Header></Header>
      <FeaturedArea></FeaturedArea>
      <AboutUs></AboutUs>
      <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
        <div
          className="fixedBg bg-left-top bg-no-repeat opacity-20"
          style={{ backgroundImage: `url(${PatternImg})` }}
        ></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <SectionTitle title="My" titleInner="Awards" desc=""></SectionTitle>
          <ImageGallery />
          <div className="viewAll text-center w-full mt-[0.125rem] md:mt-[0.0625rem] mb-6 md:mb-0">
            <p className="text-black font-NunitoSans text-[1rem] md:text-[1.125rem]">
              Want to see my award.{" "}
              <Link
                className="text-accent font-Poppins font-medium hover:text-accent underline underline-offset-8"
                to="/award"
                title="Click here to View More"
              >
                Click here to View More
              </Link>
            </p>
            {/* View All */}
          </div>
        </div>
      </section>
      <MyWorkExperience></MyWorkExperience>
      <Portfolio></Portfolio>
      <section className="portWrap relative w-full">
        <div
          className="fixedBg bg-left-top bg-no-repeat opacity-20"
          style={{ backgroundImage: `url(${PatternImg})` }}
        ></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <SectionTitle
            title="My"
            titleInner="Recent Projects"
            desc=""
          ></SectionTitle>

          <HomePosters></HomePosters>
        </div>
      </section>
      <FreelanceWork></FreelanceWork>
      <Testimonials></Testimonials>
      <BlogPost></BlogPost>
      <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
        <div
          className="fixedBg bg-left-top bg-no-repeat opacity-20"
          style={{ backgroundImage: `url(${PatternImg})` }}
        ></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <SectionTitle
            title=""
            titleInner="Press Release"
            desc=""
          ></SectionTitle>

          <Published></Published>
        </div>
      </section>
      <Recommendation></Recommendation>
      <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
        <div
          className="fixedBg bg-left-top bg-no-repeat opacity-20"
          style={{ backgroundImage: `url(${PatternImg})` }}
        ></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <SectionTitle
            title=""
            titleInner="Appreciation from the Luminaries"
            desc=""
          ></SectionTitle>

          <Carousel9 />
        </div>
      </section>
      <Partners></Partners>
      <ContactInfoProfiles></ContactInfoProfiles>
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
    </Fragment>
  );
};

export default HomePage;
