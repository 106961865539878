import "slick-carousel/slick/slick.css";

import { useCallback, useState } from "react";

import CR1 from "../assets/images/CR1.jpg";
import CR2 from "../assets/images/CR2.jpg";
import CR3 from "../assets/images/CR3.jpg";
import CR4 from "../assets/images/CR4.jpg";
import CR5 from "../assets/images/CR5.jpg";
import CR6 from "../assets/images/CR6.jpg";
import CR7 from "../assets/images/CR7.jpg";
import CR8 from "../assets/images/CR8.jpg";
import SectionBg from "../assets/images/secBg4.png";
import { SectionTitleLight } from "./SectionTitles";

const Recommendation = (props) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  // The slider images array
  const sliderImages = [CR1, CR2, CR3, CR4, CR5, CR6, CR7, CR8];
  const prevSlider = () => {
    setCurrentSlider((currentSlider) =>
      currentSlider === 0 ? sliderImages.length - 1 : currentSlider - 1
    );
  };
  const nextSlider = useCallback(() => {
    setCurrentSlider((currentSlider) =>
      currentSlider === sliderImages.length - 1 ? 0 : currentSlider + 1
    );
  }, [sliderImages.length]);

  // if you don't want to change the slider automatically then you can just remove the useEffect
  //  useEffect(() => {
  //    const intervalId = setInterval(() => {
  //      nextSlider();
  //    }, 3000);
  //    return () => clearInterval(intervalId);
  //  }, [nextSlider, currentSlider]);

  return (
    <section className="testiWrap pt-[5rem] md:pt-[11rem] lg:pt-[13rem]  pb-[4.5rem] sm:pb-[5.5rem] md:pb-[6.5rem] lg:pb-[8.75rem] relative w-full">
      <span className="bg-accent absolute right-0 bottom-[-.75rem] h-6 w-1/2"></span>
      <div
        className="fixedBg bg-center bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-90 before:bg-dark bg-dark gradBg1 bg-blend-color-dodge xl:rounded-tl-[20rem] lg:rounded-tl-[18rem] md:rounded-tl-[15rem] sm:rounded-tl-[10rem] rounded-tl-0 overflow-hidden"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>

      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitleLight
          title=""
          titleInner="Achievement"
          desc=""
        ></SectionTitleLight>
        <div className="mx-auto">
          <div className="relative mx-auto w-fit">
            {/* arrow left */}
            <button
              onClick={prevSlider}
              className="absolute -left-6 top-1/2 flex h-6 w-6 items-center justify-center md:h-8 md:w-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="icon h-4 w-4 md:h-6 md:w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"></path>
                </g>
              </svg>
            </button>
            {/* arrow right */}
            <button
              onClick={nextSlider}
              className="absolute -right-6 top-1/2 flex h-6 w-6 items-center justify-center md:h-8 md:w-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="icon h-4 w-4 md:h-6 md:w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                transform="rotate(180)"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"></path>
                </g>
              </svg>
            </button>
            <div className="w-full max-w-72 overflow-hidden">
              {/* slider container */}
              <div
                className="flex transform-gpu duration-500 ease-linear"
                style={{ transform: `translateX(-${currentSlider * 100}%)` }}
              >
                {/* sliders */}
                {sliderImages.map((slide, inx) => (
                  <img
                    width={120}
                    height={60}
                    key={inx}
                    src={slide}
                    className="mx-[2.5%] h-full min-w-[95%] rounded-2xl border-0  object-cover"
                    alt={`Slider - ${inx + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials Wrap */}
    </section>
  );
};

export default Recommendation;
