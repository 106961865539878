import { BrowserRouter, Route, Routes } from "react-router-dom";

import BlogPage2 from './../pages/BlogPages/BlogPage2';
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage } from "../pages/HomePages";
import MyAwards from "../pages/MyAwards/MyAwards";
import MyPublished from "../pages/PortfolioPages/MyPublished";
import PortfolioPage3 from './../pages/PortfolioPages/PortfolioPage3';
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import Video from "../pages/PortfolioPages/Video";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage></HomePage>} />
            <Route path="/blog" element={<BlogPage2></BlogPage2>} />
            <Route
              path="/gallery"
              element={<PortfolioPage3></PortfolioPage3>}
            />
            <Route path="/award" element={<MyAwards></MyAwards>} />
            <Route path="/video" element={<Video></Video>} />
            <Route
              path="/press"
              element={<MyPublished></MyPublished>}
            />
            <Route path="/contact" element={<ContactPage></ContactPage>} />
            <Route path="/404" element={<ErrorPage></ErrorPage>} />
            <Route path="*" element={<ErrorPage></ErrorPage>} />
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;
