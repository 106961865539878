

export const ResponsiveMenuData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Award",
    path: "/award",
  },
  {
    title: "Video",
    path: "/video",
  },
  {
    title: "Gallery",
    path: "/gallery",
  },
  {
    title: "press",
    path: "/Press",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
