import PostImg1 from "../assets/images/resources/postImg1-1.png";
import PostImg2 from "../assets/images/resources/postImg1-2.png";
import PostImg3 from "../assets/images/resources/postImg1-3.png";
import PostImg4 from "../assets/images/resources/postImg1-4.jpg";
import PostImg5 from "../assets/images/resources/postImg1-5.jpg";
import PostImg6 from "../assets/images/resources/postImg1-6.jpg";

export const posts = [
  {
    id: 1,
    postImg: PostImg1,
    postDate: "January 21, 2022",
    postDateLink: "#",
    postTitle: "Vocalist Reshmi Mirza in Premer Laddu",
    postLink: "#",
    postAuthor: "Ittefaq",
    postAuthorLink:
      "https://www.ittefaq.com.bd/amp/489295/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A7%87%E0%A6%AE%E0%A7%87%E0%A6%B0-%E0%A6%B2%E0%A6%BE%E0%A6%A1%E0%A7%8D%E0%A6%A1%E0%A7%81%E0%A6%A4%E0%A7%87-%E0%A6%A4%E0%A6%AE%E0%A6%BE-%E0%A6%AE%E0%A6%BF%E0%A6%B0%E0%A7%8D%E0%A6%9C%E0%A6%BE",
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "February 18, 2022",
    postDateLink: "#",
    postTitle: "Toma Mirza starrer song Premer Laddu released",
    postLink: "#",
    postAuthor: "tbsnews",
    postAuthorLink:
      "https://www.tbsnews.net/splash/toma-mirza-starrer-song-premer-laddu-released-368095",
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "April 05, 2022",
    postDateLink: "#",
    postTitle: "A cup of tea with Reshmi. Tell us a little about yourself.",
    postLink: "#",
    postAuthor: "The Daily Star",
    postAuthorLink:
      "https://www.thedailystar.net/showbiz/interview/news/cup-tea-reshmi-1630480",
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "April 05, 2022",
    postDateLink: "#",
    postTitle: "Multiethnic colleagues sitting at desk looking",
    postLink: "#",
    postAuthor: "Martha Beth",
    postAuthorLink: "#",
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "January 21, 2022",
    postDateLink: "#",
    postTitle: "Shot of two work colleage using a digital tablet",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "April 05, 2022",
    postDateLink: "#",
    postTitle: "Beautiful Specialist with Short Pink Hair Talks",
    postLink: "#",
    postAuthor: "Martha Beth",
    postAuthorLink: "#",
  },
];
