import { BottomBar2 } from "../../components/BottomBar";
import { ContactUs2 } from "../../components/ContactUs";
import G1 from "../../assets/press/pp1.jpg";
import G10 from "../../assets/press/pp10.jpg";
import G11 from "../../assets/press/pp11.jpg";
import G12 from "../../assets/press/pp12.jpg";
import G13 from "../../assets/press/pp13.jpg";
import G14 from "../../assets/press/pp14.jpg";
import G15 from "../../assets/press/pp15.jpg";
import G16 from "../../assets/press/pp16.jpg";
import G17 from "../../assets/press/pp17.jpg";
import G18 from "../../assets/press/pp18.jpg";
import G19 from "../../assets/press/pp19.jpg";
import G2 from "../../assets/press/pp2.jpg";
import G20 from "../../assets/press/pp20.jpg";
import G21 from "../../assets/press/pp21.jpg";
import G22 from "../../assets/press/pp22.jpg";
import G23 from "../../assets/press/pp23.jpg";
import G24 from "../../assets/press/pp24.jpg";
import G25 from "../../assets/press/pp25.jpg";
import G26 from "../../assets/press/pp26.jpg";
import G27 from "../../assets/press/pp27.jpg";
import G28 from "../../assets/press/pp28.jpg";
import G29 from "../../assets/press/pp29.jpg";
import G3 from "../../assets/press/pp3.jpg";
import G30 from "../../assets/press/pp30.jpg";
import G31 from "../../assets/press/pp31.jpg";
import G32 from "../../assets/press/pp32.jpg";
import G33 from "../../assets/press/pp33.jpg";
import G34 from "../../assets/press/pp34.jpg";
import G35 from "../../assets/press/pp35.jpg";
import G36 from "../../assets/press/pp36.jpg";
import G37 from "../../assets/press/pp37.jpg";
import G38 from "../../assets/press/pp38.jpg";
import G39 from "../../assets/press/pp39.jpg";
import G4 from "../../assets/press/pp4.jpg";
import G5 from "../../assets/press/pp5.jpg";
import G6 from "../../assets/press/pp6.jpg";
import G7 from "../../assets/press/pp7.jpg";
import G8 from "../../assets/press/pp8.jpg";
import G9 from "../../assets/press/pp9.jpg";
import { Gellary } from "./Gellary";
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/PageTitle";
import React from "react";

const MyPublished = () => {
  const sliders = [
    G1,
    G2,
    G3,
    G4,
    G5,
    G6,
    G7,
    G8,
    G9,
    G10,
    G11,
    G12,
    G13,
    G14,
    G15,
    G16,
    G17,
    G18,
    G19,
    G20,
    G21,
    G22,
    G23,
    G24,
    G25,
    G26,
    G27,
    G28,
    G29,
    G30,
    G31,
    G32,
    G33,
    G34,
    G35,
    G36,
    G37,
    G38,
    G39,
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Press Release - Resmi Mirza</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Press Release"
        hometitle="Home"
        homeURL="/"
        currentPage="Press Release"
      ></PageTitle>

      <div className="mx-auto grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-3 lg:grid-cols-4 md:w-[70%] w-[90%] my-4 md:my-8">
        {sliders.map((data, inx) => (
          <div key={inx}>
            <Gellary data={data}></Gellary>
          </div>
        ))}
      </div>
      <ContactUs2></ContactUs2>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default MyPublished;
