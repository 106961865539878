import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import PB1 from "../assets/images/Papercut1.jpg";
import PB10 from "../assets/images/Papercut10.jpg";
import PB11 from "../assets/images/Papercut11.jpg";
import PB12 from "../assets/images/Papercut12.jpg";
import PB14 from "../assets/images/Papercut14.jpg";
import PB15 from "../assets/images/Papercut15.jpg";
import PB16 from "../assets/images/Papercut16.jpg";
import PB17 from "../assets/images/Papercut17.jpg";
import PB20 from "../assets/images/Papercut19.jpg";
import PB21 from "../assets/images/Papercut20.jpg";
import PB22 from "../assets/images/Papercut21.jpg";
import PB23 from "../assets/images/Papercut22.jpg";
import PB24 from "../assets/images/Papercut23.jpg";
import PB25 from "../assets/images/Papercut24.jpg";
import PB27 from "../assets/images/Papercut27.jpg";
import PB28 from "../assets/images/Papercut28.jpg";
import PB30 from "../assets/images/Papercut30.jpg";
import PB31 from "../assets/images/Papercut31.jpg";
import PB32 from "../assets/images/Papercut32.jpg";
import PB33 from "../assets/images/Papercut33.jpg";
import PB34 from "../assets/images/Papercut34.jpg";
import PB35 from "../assets/images/Papercut35.jpg";
import PB36 from "../assets/images/Papercut36.jpg";
import PB37 from "../assets/images/Papercut37.jpg";
import PB38 from "../assets/images/Papercut38.jpg";
import PB39 from "../assets/images/Papercut39.jpg";
import PB4 from "../assets/images/Papercut4.jpg";
import PB40 from "../assets/images/Papercut40.jpg";
import PB41 from "../assets/images/Papercut41.jpg";
import PB42 from "../assets/images/Papercut42.jpg";
import PB43 from "../assets/images/Papercut43.jpg";
import PB44 from "../assets/images/Papercut44.jpg";
import PB45 from "../assets/images/Papercut45.jpg";
import PB46 from "../assets/images/Papercut46.jpg";
import PB47 from "../assets/images/Papercut47.jpg";
import PB48 from "../assets/images/Papercut48.jpg";
import PB5 from "../assets/images/Papercut5.jpg";
import PB6 from "../assets/images/Papercut6.jpg";
import PB7 from "../assets/images/Papercut7.jpg";
import PB8 from "../assets/images/Papercut8.jpg";
import PB9 from "../assets/images/Papercut9.jpg";

const Published = () => {
    const array = [
      {
        url: PB1,
      },
      {
        url: PB4,
      },
      {
        url: PB5,
      },
      {
        url: PB6,
      },
      {
        url: PB7,
      },
      {
        url: PB8,
      },
      {
        url: PB9,
      },
      {
        url: PB10,
      },
      {
        url: PB11,
      },
      {
        url: PB12,
      },
      {
        url: PB14,
      },
      {
        url: PB15,
      },
      {
        url: PB16,
      },
      {
        url: PB17,
      },
      {
        url: PB20,
      },
      {
        url: PB21,
      },
      {
        url: PB22,
      },
      {
        url: PB23,
      },
      {
        url: PB24,
      },
      {
        url: PB25,
      },
      {
        url: PB27,
      },
      {
        url: PB28,
      },
      {
        url: PB30,
      },
      {
        url: PB31,
      },
      {
        url: PB32,
      },
      {
        url: PB33,
      },
      {
        url: PB34,
      },
      {
        url: PB35,
      },
      {
        url: PB36,
      },
      {
        url: PB37,
      },
      {
        url: PB38,
      },
      {
        url: PB39,
      },
      {
        url: PB40,
      },
      {
        url: PB41,
      },
      {
        url: PB42,
      },
      {
        url: PB43,
      },
      {
        url: PB44,
      },
      {
        url: PB45,
      },
      {
        url: PB46,
      },
      {
        url: PB47,
      },
      {
        url: PB48,
      }
       
    ];

    const [currentSlider, setCurrentSlider] = useState(0);
    // The slider images array
    const prevSlider = () =>
      setCurrentSlider((currentSlider) =>
        currentSlider === 0 ? array.length - 2 : currentSlider - 1
      );
    const nextSlider = () =>
      setCurrentSlider((currentSlider) =>
        currentSlider === array.length - 2 ? 0 : currentSlider + 1
      );
    // if you don't want to change the slider automatically then you can just remove the useEffect
    useEffect(() => {
      const intervalId = setInterval(() => {
        nextSlider();
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }, [currentSlider]);

    const isSmallScreen = window.innerWidth <= 768;
    return (
      <div>
        <div className="mx-auto flex flex-row items-center overflow-hidden gap-5 lg:gap-10 px-8 md:px-16 lg:px-24">
          <div className="relative overflow-hidden">
            <div className="absolute w-full h-full flex items-center justify-between z-50 px-5">
              {/* arrow left */}
              <button
                onClick={prevSlider}
                className="flex justify-center items-center bg-accent rounded-full w-6 h-6 md:w-8 md:h-8"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="w-4 h-4 md:w-6 md:h-6 icon text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                >
                  {" "}
                  <g strokeWidth="0"></g>{" "}
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>{" "}
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill="white"
                      d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"
                    ></path>
                  </g>
                </svg>
              </button>
              {/* arrow right */}
              <button
                onClick={nextSlider}
                className="flex justify-center items-center bg-accent rounded-full w-6 h-6 md:w-8 md:h-8"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="w-4 h-4 md:w-6 md:h-6 icon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  transform="rotate(180)"
                >
                  {" "}
                  <g strokeWidth="0"></g>{" "}
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>{" "}
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill="white"
                      d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            {/* slider container */}
            <div
              className="ease-linear duration-300 flex"
              style={{
                transform: `translateX(-${
                  currentSlider * (isSmallScreen ? 90 : 10)
                }%)`,
              }}
            >
              {/* sliders */}
              {array.map((each, idx) => (
                <div
                  key={idx}
                  className="p-4 min-w-full md:min-w-[60%] lg:min-w-[35%]"
                >
                  <div className="">
                    <img className="w-80 h-72" src={each.url} alt="my_photo" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="viewAll text-center w-full mt-[3.125rem] md:mt-[4.0625rem] mb-6 md:mb-0">
          <p className="text-desc2 font-NunitoSans text-[1rem] md:text-[1.125rem]">
            Want to see my press release.{" "}
            <Link
              className="text-accent font-Poppins font-medium hover:text-accent2 underline underline-offset-8 z-10"
              to="/press"
              title="Click here to View More"
            >
              Click here to View More
            </Link>
          </p>
          {/* View All */}
        </div>
      </div>
    );
};

export default Published;