import * as FiIcons from "react-icons/fi";

export const MenuData = [
  {
    title: "Home",
    path: "/",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Blog",
    path: "/blog",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Award",
    path: "/award",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Video",
    path: "/video",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Gallery",
    path: "/gallery",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Press",
    path: "/press",
    iconClosed: <FiIcons.FiChevronDown className="text-white" />,
    iconOpened: <FiIcons.FiChevronUp className="text-white" />,
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
