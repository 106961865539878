import PartHoverImg1 from "../assets/images/resources/partImg1-1.png";
import PartHoverImg2 from "../assets/images/resources/partImg1-2.png";
import PartHoverImg3 from "../assets/images/resources/partImg1-3.png";
import PartHoverImg4 from "../assets/images/resources/partImg1-4.png";
import PartHoverImg5 from "../assets/images/resources/partImg1-5.png";
import PartImg1 from "../assets/images/resources/partImg1-1.png";
import PartImg10 from "../assets/images/resources/Rtv.png";
import PartImg2 from "../assets/images/resources/partImg1-2.png";
import PartImg3 from "../assets/images/resources/partImg1-3.png";
import PartImg4 from "../assets/images/resources/partImg1-4.png";
import PartImg5 from "../assets/images/resources/partImg1-5.png";
import PartImg6 from "../assets/images/resources/Channel i.png";
import PartImg7 from "../assets/images/resources/Boishaki TV.png";
import PartImg8 from "../assets/images/resources/Mohona TV.png";
import PartImg9 from "../assets/images/resources/Deepto TV.png";

export const partners = [
  {
    partImg: PartImg1,
    partHoverImg: PartHoverImg1,
    partLink: "#",
  },
  {
    partImg: PartImg2,
    partHoverImg: PartHoverImg2,
    partLink: "#",
  },
  {
    partImg: PartImg3,
    partHoverImg: PartHoverImg3,
    partLink: "#",
  },
  {
    partImg: PartImg4,
    partHoverImg: PartHoverImg4,
    partLink: "#",
  },
  {
    partImg: PartImg5,
    partHoverImg: PartHoverImg5,
    partLink: "#",
  },
  {
    partImg: PartImg6,
    partHoverImg: PartImg6,
    partLink: "#",
  },
  {
    partImg: PartImg7,
    partHoverImg: PartImg7,
    partLink: "#",
  },
  {
    partImg: PartImg8,
    partHoverImg: PartImg8,
    partLink: "#",
  },
  {
    partImg: PartImg9,
    partHoverImg: PartImg9,
    partLink: "#",
  },
  {
    partImg: PartImg10,
    partHoverImg: PartImg10,
    partLink: "#",
  },
];
