import { ReactComponent as BehanceIcon } from "../lib/icons/FB.svg";
import { ReactComponent as Deezer } from "../lib/icons/deezer.svg";
import { ReactComponent as DribbbleIcon } from "../lib/icons/ig.svg";
import { ReactComponent as Gaana } from "../lib/icons/gaana.svg";
import { ReactComponent as Musicapple } from "../lib/icons/musicapple.svg";
import { ReactComponent as SkypeIcon } from "../lib/icons/Yt.svg";

export const profile2 = [
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#d74579] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#d74579]",
    profIcon: (
      <DribbbleIcon className="h-[4.25rem] w-[4.25rem] group-hover:brightness-[10]"></DribbbleIcon>
    ),
    profSubTitle: "",
    profTitle: "Instagram",
    profLinkCap: "Check Now",
    profLink: "https://www.instagram.com/reshmipower",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#4176fa] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#4176fa]",
    profIcon: (
      <BehanceIcon className="h-[4.25rem] w-[4.25rem] group-hover:contrast-[4]"></BehanceIcon>
    ),
    profSubTitle: "",
    profTitle: "Facebook",
    profLinkCap: "Check Now",
    profLink: "https://www.facebook.com/reshmi.mirza.999",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#EE2E24] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#FF0000]",
    profIcon: (
      <SkypeIcon className="h-[4.25rem] w-[4.25rem] group-hover:contrast-200"></SkypeIcon>
    ),
    profSubTitle: "",
    profTitle: "Youtube",
    profLinkCap: "Get In Touch",
    profLink: "https://www.youtube.com/@reshmimirza9752",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#A238FF] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#A238FF]",
    profIcon: (
      <Deezer className="h-[4.25rem] w-[4.25rem] group-hover:brightness-[10]"></Deezer>
    ),
    profSubTitle: "",
    profTitle: "Deezer",
    profLinkCap: "Check Now",
    profLink: "https://www.deezer.com/sv/artist/160144082",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#FA2941] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#FA2941]",
    profIcon: (
      <Musicapple className="h-[4.25rem] w-[4.25rem] group-hover:contrast-[4]"></Musicapple>
    ),
    profSubTitle: "",
    profTitle: "Apple Music",
    profLinkCap: "Check Now",
    profLink:
      "https://music.apple.com/ca/album/premer-laddu-feat-reshmi-mirza-single/1609317341",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#FF0000] h-[23.125rem] before:z-[-1] hover:before:h-full z-[1]",
    titleColor: "text-[#FF0000]",
    profIcon: (
      <Gaana className="h-[4.25rem] w-[4.25rem] group-hover:contrast-200"></Gaana>
    ),
    profSubTitle: "",
    profTitle: "Gaana",
    profLinkCap: "Get In Touch",
    profLink: "https://gaana.com/artist/reshmi-mirza",
  },
];
