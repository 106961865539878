import { ReactComponent as FacebookIcon } from "../lib/icons/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../lib/icons/Instagram.svg";
import { ReactComponent as YoutubeIcon } from "../lib/icons/Youtube.svg";

export const social4 = [
  {
    socialIcon: (
      <FacebookIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-accent hover:fill-accent"></FacebookIcon>
    ),
    socialLink: "https://www.facebook.com/reshmi.mirza.999",
    socialTitle: "Facebook",
  },
  {
    socialIcon: (
      <InstagramIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></InstagramIcon>
    ),
    socialLink:
      "https://www.instagram.com/reshmipower?igsh=MWI5eGpsZzY3eTdlYg==",
    socialTitle: "Instagram",
  },
  {
    socialIcon: (
      <YoutubeIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#0ddae1] hover:fill-accent"></YoutubeIcon>
    ),
    socialLink: "https://www.youtube.com/@reshmimirza9752",
    socialTitle: "Youtube",
  },
];
