import { ReactComponent as EnvelopeIcon } from "../lib/icons/Envelope.svg";
import { ReactComponent as HomeIcon } from "../lib/icons/Home.svg";
import { ReactComponent as PhoneIcon } from "../lib/icons/Phone.svg";

export const conInfo2 = [
  {
    conIcon: <HomeIcon className="h-[3.4375rem] fill-white"></HomeIcon>,
    conTitle: "Mr. Salman",
    conCap: "",
  },
  {
    conIcon: <PhoneIcon className="h-[3.4375rem] fill-white"></PhoneIcon>,
    conTitle: "Phone:",
    conCap: "+88 0171 223 6728",
  },
  {
    conIcon: <EnvelopeIcon className="h-[3.4375rem] fill-white"></EnvelopeIcon>,
    conTitle: "Email Address:",
    conCap: "salmandrums@gmail.com",
  },
];
