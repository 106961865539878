import { BottomBar2 } from "../../components/BottomBar";
import { ContactUs2 } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/PageTitle";
import React from "react";
import YoutubeVideo from "./YoutubeVideo";

const Video = () => {
  

  return (
    <React.Fragment>
      <Helmet>
        <title>Video - Resmi Mirza</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Video"
        hometitle="Home"
        homeURL="/"
        currentPage="Video"
      ></PageTitle>
      <section className="workExpWrap relative w-full">
        <div className="fixedBg bg-left-top bg-no-repeat opacity-20"></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto"></div>
      </section>
      <div className="pageWrap pt-20 pb-20 md:pb-[6.875rem] relative w-full">
        <YoutubeVideo></YoutubeVideo>
      </div>
      <ContactUs2></ContactUs2>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default Video;
