import { ReactComponent as EnvelopeIcon } from "../lib/icons/Envelope.svg";
import { ReactComponent as PhoneIcon } from "../lib/icons/Phone.svg";
import { ReactComponent as UserIcon } from "../lib/icons/Home.svg";

export const conInfo = [
  {
    conIcon: <UserIcon className="h-[3.4375rem] fill-white"></UserIcon>,
    conTitle: "Booking worldwide/ management/ press",
    conCap: "Mr. Salman (Manager)",
  },
  {
    conIcon: <PhoneIcon className="h-[3.4375rem] fill-white"></PhoneIcon>,
    conTitle: "Phone:",
    conCap: "+88 0171 223 6728",
  },
  {
    conIcon: <EnvelopeIcon className="h-[3.4375rem] fill-white"></EnvelopeIcon>,
    conTitle: "Email Address:",
    conCap: "salmandrums@gmail.com",
  },
];
