import A1 from "../../assets/Award/Award1/Slide1.JPG";
import A2 from "../../assets/Award/Award1/Slide2.JPG";
import A3 from "../../assets/Award/Award1/Slide3.JPG";
import A4 from "../../assets/Award/Award1/Slide4.JPG";
import React from "react";

const ImageGallery = () => {
  return (
    <section className="bg-zinc-50 overflow-hidden py-16">
      <div className="max-w-screen-xl 2xl:max-w-screen-3xl px-8 md:px-12 mx-auto py-12 lg:py-24 space-y-24 h-svh flex flex-col justify-center">
        <div className="flex flex-col sm:flex-row mx-auto">
          <a href="#_" aria-label="Image 1">
            <img
              src={A1}
              className="rounded-xl rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
              alt="Image 1"
            />
          </a>
          <a href="#_" aria-label="Image 2">
            <img
              src={A2}
              className="rounded-xl -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
              alt="Image 2"
            />
          </a>
          <a href="#_" aria-label="Image 3">
            <img
              src={A3}
              className="rounded-xl rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
              alt="Image 3"
            />
          </a>
          <a href="#_" aria-label="Image 4">
            <img
              src={A4}
              className="rounded-xl -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
              alt="Image 4"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ImageGallery;
