import PostImg1 from "../assets/images/resources/postImg2-1.png";
import PostImg2 from "../assets/images/resources/postImg2-2.png";
import PostImg3 from "../assets/images/resources/postImg2-3.png";
import PostImg4 from "../assets/images/resources/postImg2-4.png";
import PostImg5 from "../assets/images/resources/postImg2-5.png";
import PostImg6 from "../assets/images/resources/postImg2-6.png";
import PostImg7 from "../assets/images/resources/postImg2-7.png";

export const posts2 = [
  {
    id: 1,
    postImg: PostImg1,
    postDate: "07",
    postMonth: "February",
    postDateLink: "#",
    postTitle: "Vocalist Reshmi Mirza in Premer Laddu",
    postLink: "#",
    postAuthor: "Ittefaq",
    postAuthorLink:
      "https://www.ittefaq.com.bd/amp/489295/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A7%87%E0%A6%AE%E0%A7%87%E0%A6%B0-%E0%A6%B2%E0%A6%BE%E0%A6%A1%E0%A7%8D%E0%A6%A1%E0%A7%81%E0%A6%A4%E0%A7%87-%E0%A6%A4%E0%A6%AE%E0%A6%BE-%E0%A6%AE%E0%A6%BF%E0%A6%B0%E0%A7%8D%E0%A6%9C%E0%A6%BE",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "30",
    postMonth: "August ",
    postDateLink: "#",
    postTitle: "Became a model organized by Fried",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink: "https://www.bd-pratidin.com/friday/2019/08/30/452466",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "09",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Reshmir's surprise",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment-news/2019/06/09/430034",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "6",
    postMonth: "February",
    postDateLink: "#",
    postTitle: "Two Mirzas brought 'Premer Laddu'",
    postLink: "#",
    postAuthor: "Dhaka Post",
    postAuthorLink: "https://www.dhakapost.com/entertainment/95967",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "12",
    postMonth: "August",
    postDateLink: "#",
    postTitle: "Shahid-Reshmi's 'Gan Alapan'",
    postLink: "#",
    postAuthor: "Dhaka Post",
    postAuthorLink: "https://www.dhakapost.com/entertainment/53499",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 7,
    postImg: PostImg7,
    postDate: "17",
    postMonth: "July",
    postDateLink: "#",
    postTitle: "Reshmi Mirza in movie song",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment-news/2024/07/17/1011017",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "4",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Reshmi Mirza at Folk Live",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment/2021/06/03/655633",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
];
