import React from 'react';
import Youtube from './Youtube';

const App = () => {
    return (
      <div>
        <Youtube></Youtube>
      </div>
    );
};

export default App;