import { Link } from "react-router-dom";
import PatternImg from "../../assets/images/patternImg.jpg";
import { SectionTitle } from "../SectionTitles";
import { portfolio } from "../../data/portfolio";

const Portfolio = () => {
  return (
    <section className="portWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
      <div
        className="fixedBg bg-left-top bg-no-repeat opacity-20"
        style={{ backgroundImage: `url(${PatternImg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitle
          title="My Amazing"
          titleInner="Works"
          desc=""
        ></SectionTitle>
        <div className="portList flex flex-wrap relative mx-[-.9375rem] mb-[-1.875rem]">
          {portfolio.map(
            (item, index) =>
              index < 5 && (
                <div className={item.portBox} key={index}>
                  <div className="portBox group mb-[1.875rem] before:transition-all before:ease-in-out before:duration-500 before:bg-accent before:absolute before:opacity-0 before:hover:opacity-50 before:inset-[10%] before:hover:inset-0 relative overflow-hidden rounded-[10px] sm:rounded-[1.25rem] lg:rounded-[1.875rem] xl:rounded-[3.125rem] before:z-[1] text-center w-full">
                    <img
                      className="w-full"
                      src={item.portImg}
                      alt={item.portTitle}
                    ></img>
                  </div>
                  {/* Portfolio Box */}
                </div>
              )
          )}
          {/* Portfolio List */}
        </div>
        <div className="viewAll text-center w-full mt-[3.125rem] md:mt-[4.0625rem] mb-6 md:mb-0">
          <p className="text-desc2 font-NunitoSans text-[1rem] md:text-[1.125rem]">
            Want to see my photo.{" "}
            <Link
              className="text-accent font-Poppins font-medium hover:text-accent2 underline underline-offset-8"
              to="/gallery"
              title="Click here to View More"
            >
              Click here to View More
            </Link>
          </p>
          {/* View All */}
        </div>
      </div>
      {/* Portfolio Wrap */}
    </section>
  );
};

export default Portfolio;
