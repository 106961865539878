import "./styles.css";

import YoutubeEmbed from "../../components/MyWorkExperience/YoutubeEmbed";

export default function YoutubeVideo() {
  return (
    <div className="App grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-[90%] max-w-6xl mx-auto">
      <YoutubeEmbed embedId="tstW58nY-jU?si=aF2mn_j-FmSTy9gq&amp;start=5294" />
      <YoutubeEmbed embedId="59_OgfFahms?si=mDD6QJnSOQ5fW4IM&amp;start=35" />
      <YoutubeEmbed embedId="Ki3V53CPhUQ?si=TMieJOYL6F8U2n1Q&amp;start=16" />
      <YoutubeEmbed embedId="BDdA2AZz-yo?si=EopbXRD76XVxJXB1&amp;start=25" />
      <YoutubeEmbed embedId="_r58G5iM_NU?si=IfAILD_J4QLyYgIQ&amp;start=51" />
      <YoutubeEmbed embedId="_PgrPnIcQTE?si=LX4LNCpzHkz8xN3W&amp;start=23" />
      <YoutubeEmbed embedId="dqLMLrvUPXw?si=i4FrpLJyFStDYwM9&amp;start=16" />
      <YoutubeEmbed embedId="A-V-uQmeYdg?si=osnqL1VNHUsJcgmT&amp;start=22" />
      <YoutubeEmbed embedId="vC_bAP0iFBo?si=OFzVH6KQ_nlTDQ7j&amp;start=47" />
      <YoutubeEmbed embedId="ya0xRoArdtg?si=Phd6cqiMMT0kXqw9&amp;start=100" />
      <YoutubeEmbed embedId="OBj6YzttdgU?si=BJr9nm5mXMiyWBrX&amp;start=49" />
      <YoutubeEmbed embedId="DZzxUDok31c?si=Cn0KEqiAgcAscHvm&amp;start=68" />
      <YoutubeEmbed embedId="HGJEJOeFEkU?si=-txLBywBsnZjbHff&amp;start=18" />
      <YoutubeEmbed embedId="1jpRApL4or0?si=136SRVZyIPaedm5Q&amp;start=77" />
      <YoutubeEmbed embedId="Ttlr1danr5A?si=qjN9rh4UD02WcKTj&amp;start=230" />
      <YoutubeEmbed embedId="sF6v6olOQzY?si=8CGGb_ZUXDDWTOJ_&amp;start=113" />
      <YoutubeEmbed embedId="NOUEHxnwS18?si=1sTCATt-0bRPNMBL&amp;start=91" />
      <YoutubeEmbed embedId="53rYd-yGodQ?si=IKHoPyFCLtDJnrm2&amp;start=178" />
      <YoutubeEmbed embedId="YRlBtOjx_Jg?si=jDjebU1XQTfBB8zC&amp;start=165" />
      <YoutubeEmbed embedId="ZA0GeOt2NKE?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="FsM7wqmv9Lc?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="YLYhUJ1oiBo?si=aPZeq85bx3i8tQfw&amp;start=1" />
      {/* <YoutubeEmbed embedId="9M6xAhZCK-E?si=aPZeq85bx3i8tQfw&amp;start=1" /> */}
      <YoutubeEmbed embedId="qqhujo1CCxc?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="rmY7FaBl-_k?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="XoiwB6EptQc?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="B202oAvXono?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="pCuRHK69ahI?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="i0f31DTJ5-U?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="OpSY_CsqDXg?si=aPZeq85bx3i8tQfw&amp;start=1" />
      <YoutubeEmbed embedId="59_OgfFahms?si=aPZeq85bx3i8tQfw&amp;start=1" />
    </div>
  );
}
