import { ContactInfo } from "../ContactInfo";
import ContactInfo3 from './../ContactInfo/ContactInfo3';
import SectionBg from "../../assets/images/secBg5.png";
import { SectionTitleLight2 } from "../SectionTitles";
import { SocialLinks } from "../SocialLinks";

const ContactUs = () => {
  return (
    <section className="contactUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
      <div
        className="fixedBg bg-center bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-20 before:bg-accent bg-accent bg-blend-multiply overflow-hidden"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitleLight2
          title="Contact"
          titleInner="Me"
          desc="Please do not hesitate to contact me if you have any questions or concerns"
        ></SectionTitleLight2>
        <div className="contactUs grid gap-[1.875rem] lg:grid-cols-2 md:grid-cols-1 grid-cols-1 relative w-full">
          <div>
            {/* <ContactForm></ContactForm> */}
            <div className="flex flex-col gap-[1.875rem] md:gap-[2.5rem] lg:gap-[3.4375rem]">
              <ContactInfo3></ContactInfo3>
              {/* <SocialLinks></SocialLinks> */}
            </div>
          </div>
          <div className="flex flex-col gap-[1.875rem] md:gap-[2.5rem] lg:gap-[3.4375rem]">
            <ContactInfo></ContactInfo>
            <SocialLinks></SocialLinks>
          </div>
          {/* Contact Us */}
        </div>
      </div>
      {/* Contact Us Wrap */}
    </section>
  );
};

export default ContactUs;
