import "swiper/css";
import "swiper/css/pagination";

import App from "./App";
import { Link } from "react-router-dom";
import SectionBg from "../../assets/images/BGYT.png";
import { SectionTitleLight } from "../SectionTitles";

const MyWorkExperience = () => {
  return (
    <section className="workExpWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem relative w-full">
      <span className="bg-accent absolute right-0 bottom-[-.75rem] h-6 w-1/2"></span>
      <div
        className="fixedBg bg-center bg-cover bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-90 before:bg-dark bg-dark gradBg1 bg-blend-color-dodge xl:rounded-tl-[20rem] lg:rounded-tl-[18rem] md:rounded-tl-[15rem] sm:rounded-tl-[10rem] rounded-tl-0 overflow-hidden"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:max-w-[86.875rem] mx-auto">
        <SectionTitleLight
          title="My Video"
          titleInner="gallery"
          desc=""
        ></SectionTitleLight>
        <div className="">
          <App />
        </div>
      </div>
      <div className="viewAll text-center w-full mt-[3.125rem] md:mt-[4.0625rem] mb-6 md:mb-0">
        <p className="text-white font-NunitoSans text-[1rem] md:text-[1.125rem]">
          Want to see my video.{" "}
          <Link
            className="text-accent font-Poppins font-medium hover:text-accent underline underline-offset-8"
            to="/video"
            title="Click here to View More"
          >
            Click here to View More
          </Link>
        </p>
        {/* View All */}
      </div>
    </section>
  );
};

export default MyWorkExperience;
