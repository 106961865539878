import { useEffect, useState } from "react";

import Profile0 from "../../assets/images/profile/Profile0.jpg";
import Profile1 from "../../assets/images/profile/Profile1.jpg";
import Profile2 from "../../assets/images/profile/Profile2.jpg";
import Profile3 from "../../assets/images/profile/Profile3.jpg";
import Profile4 from "../../assets/images/profile/Profile4.jpg";
import Profile5 from "../../assets/images/profile/Profile5.jpg";
import Profile6 from "../../assets/images/profile/Profile6.jpg";
import Profile7 from "../../assets/images/profile/Profile7.jpg";
import Profile8 from "../../assets/images/profile/Profile8.jpg";
import Profile9 from "../../assets/images/profile/Profile9.jpg";

export const Carousel8 = () => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const sliders = [
    Profile0,
    Profile1,
    Profile2,
    Profile3,
    Profile4,
    Profile5,
    Profile6,
    Profile7,
    Profile8,
    Profile9,
  ];
  const nextSlider = () =>
    setCurrentSlider((currentSlider) =>
      currentSlider === sliders.length - 1 ? 0 : currentSlider + 1
    );
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlider();
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentSlider]);

  return (

      <div className="w-full mx-auto h-[240px] md:h-[540px] flex flex-col lg:flex-row items-center overflow-hidden gap-5 lg:gap-10 px-10">
        <div className="relative overflow-hidden">
          {/* dots */}
          <div className="flex h-fit rounded-full z-50 absolute right-0 bottom-1/2 w-fit gap-1 rotate-90">
            {sliders.map((_, inx) => (
              <button
                key={inx}
                onClick={() => setCurrentSlider(inx)}
                className={`rounded-full duration-300 bg-white ${
                  currentSlider === inx ? "w-10" : "w-2"
                } h-2`}
              ></button>
            ))}
          </div>
          {/* slider container */}
          <div
            className="ease-linear duration-300 flex flex-col h-60 sm:h-96 md:h-[540px] transform-gpu relative"
            style={{ transform: `translateY(-${currentSlider * 100}%)` }}
          >
            {/* sliders */}
            {sliders.map((_, inx) => (
              <div
                key={inx}
                className="min-w-full duration-200 before:content-['Image'] before:bg-black/20 before:-z-10 before:absolute before:text-3xl before:flex before:justify-center before:items-center before:text-black/40 before:inset-0 relative"
              >
                <img
                  src={_}
                  className="w-full h-60 sm:h-96 md:h-[540px] object-cover"
                  alt={`Slider - ${inx + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

  );
};
