import { BottomBar2 } from "../../components/BottomBar";
import { Carousel } from "./Carousel";
import { ContactUs2 } from "../../components/ContactUs";
import G1 from "../../assets/gallery/gl1.jpg";
import G10 from "../../assets/gallery/gl10.jpg";
import G11 from "../../assets/gallery/gl11.jpg";
import G12 from "../../assets/gallery/gl12.jpg";
import G13 from "../../assets/gallery/gl13.jpg";
import G14 from "../../assets/gallery/gl14.jpg";
import G15 from "../../assets/gallery/gl15.jpg";
import G16 from "../../assets/gallery/gl16.jpg";
import G17 from "../../assets/gallery/gl17.jpg";
import G18 from "../../assets/gallery/gl18.jpg";
import G19 from "../../assets/gallery/gl19.jpg";
import G2 from "../../assets/gallery/gl2.jpg";
import G20 from "../../assets/gallery/gl20.jpg";
import G21 from "../../assets/gallery/gl21.jpg";
import G22 from "../../assets/gallery/gl22.jpg";
import G23 from "../../assets/gallery/gl23.jpg";
import G24 from "../../assets/gallery/gl24.jpg";
import G25 from "../../assets/gallery/gl25.jpg";
import G26 from "../../assets/gallery/gl26.jpg";
import G27 from "../../assets/gallery/gl27.jpg";
import G28 from "../../assets/gallery/gl28.jpg";
import G29 from "../../assets/gallery/gl29.jpg";
import G3 from "../../assets/gallery/gl3.jpg";
import G30 from "../../assets/gallery/gl30.jpg";
import G31 from "../../assets/gallery/gl31.jpg";
import G32 from "../../assets/gallery/gl32.jpg";
import G33 from "../../assets/gallery/gl33.jpg";
import G34 from "../../assets/gallery/gl34.jpg";
import G35 from "../../assets/gallery/gl35.jpg";
import G36 from "../../assets/gallery/gl36.jpg";
import G37 from "../../assets/gallery/gl37.jpg";
import G38 from "../../assets/gallery/gl38.jpg";
import G39 from "../../assets/gallery/gl39.jpg";
import G4 from "../../assets/gallery/gl4.jpg";
import G40 from "../../assets/gallery/gl40.jpg";
import G41 from "../../assets/gallery/gl41.jpg";
import G42 from "../../assets/gallery/gl42.jpg";
import G43 from "../../assets/gallery/gl43.jpg";
import G44 from "../../assets/gallery/gl44.jpg";
import G45 from "../../assets/gallery/gl45.jpg";
import G46 from "../../assets/gallery/gl46.jpg";
import G47 from "../../assets/gallery/gl47.jpg";
import G48 from "../../assets/gallery/gl48.jpg";
import G49 from "../../assets/gallery/gl49.jpg";
import G5 from "../../assets/gallery/gl5.jpg";
import G50 from "../../assets/gallery/gl50.jpg";
import G51 from "../../assets/gallery/gl51.jpg";
import G52 from "../../assets/gallery/gl52.jpg";
import G53 from "../../assets/gallery/gl53.jpg";
import G54 from "../../assets/gallery/gl54.jpg";
import G55 from "../../assets/gallery/gl55.jpg";
import G56 from "../../assets/gallery/gl56.jpg";
import G57 from "../../assets/gallery/gl57.jpg";
import G58 from "../../assets/gallery/gl58.jpg";
import G59 from "../../assets/gallery/gl59.jpg";
import G6 from "../../assets/gallery/gl6.jpg";
import G60 from "../../assets/gallery/gl60.jpg";
import G61 from "../../assets/gallery/gl61.jpg";
import G62 from "../../assets/gallery/gl62.jpg";
import G63 from "../../assets/gallery/gl63.jpg";
import G64 from "../../assets/gallery/gl64.jpg";
import G65 from "../../assets/gallery/gl65.jpg";
import G66 from "../../assets/gallery/gl66.jpg";
import G7 from "../../assets/gallery/gl7.jpg";
import G8 from "../../assets/gallery/gl8.jpg";
import G9 from "../../assets/gallery/gl9.jpg";
import { Gellary } from './Gellary';
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/PageTitle";
import React from "react";

const PortfolioPage3 = () => {
 const sliders = [
   G1,
   G2,
   G3,
   G4,
   G5,
   G6,
   G7,
   G8,
   G9,
   G10,
   G11,
   G12,
   G13,
   G14,
   G15,
   G16,
   G17,
   G18,
   G19,
   G20,
   G21,
   G22,
   G23,
   G24,
   G25,
   G26,
   G27,
   G28,
   G29,
   G30,
   G31,
   G32,
   G33,
   G34,
   G35,
   G36,
   G37,
   G38,
   G39,
   G40,
   G41,
   G42,
   G43,
   G44,
   G45,
   G46,
   G47,
   G48,
   G49,
   G50,
   G51,
   G52,
   G53,
   G54,
   G55,
   G56,
   G57,
   G58,
   G59,
   G60,
   G61,
   G62,
   G63,
   G64,
   G65,
   G66,
 ];


  return (
    <React.Fragment>
      <Helmet>
        <title>Gallery - Resmi Mirza</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Gallery"
        hometitle="Home"
        homeURL="/"
        currentPage="Gallery"
      ></PageTitle>
      <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
        <div className="fixedBg bg-left-top bg-no-repeat opacity-20"></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <Carousel></Carousel>
        </div>
      </section>
      
      <div className="mx-auto grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-3 lg:grid-cols-4 md:w-[70%] w-[90%] my-4 md:my-8">
        {sliders.map((data, inx) => (
          <div key={inx}>
            <Gellary data={data}></Gellary>
          </div>
        ))}
      </div>
      <ContactUs2></ContactUs2>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default PortfolioPage3;
