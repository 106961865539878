import { Link } from "react-router-dom";

const MenuLinks = () => {
  return (
    <nav className="relative hidden lg:block">
      <ul className="flex flex-col lg:flex-row gap-4 lg:gap-10">
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Home
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/blog"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Blog
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/award"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Award
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/video"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Video
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/gallery"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Gallery
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            to="/press"
            className="group-hover:text-accent text-accent2 pr-5 relative block cursor-pointer"
          >
            Press
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/contact"
            title="Contact"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLinks;
