import AboutImg from "../../assets/images/resources/2.png";
import { Carousel8 } from './AboutPic';
import PatternImg from "../../assets/images/patternImg.jpg";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import Resmi from "../../lib/icons/resmimirza.png";
import { SectionTitle } from "../SectionTitles";
import { useState } from "react";

// import { ReactComponent as Signature } from "../../lib/icons/resmimirza.svg";


const AboutUs = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
      <div
        className="fixedBg bg-left-top bg-no-repeat opacity-20"
        style={{ backgroundImage: `url(${PatternImg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitle title="" titleInner="Biography" desc=""></SectionTitle>
        <div className="aboutUs relative w-full p-[1.25rem] lg:p-[1.875rem] mt-[5.9375rem]">
          <div className="aboutUsBg before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[3.125rem] before:opacity-70 before:z-[1] bg-gray bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 left-0 lg:left-[10.9375rem] bg-no-repeat bg-center bg-cover"></div>
          <div className="grid gap-[1.875rem] lg:grid-cols-2 grid-cols-1">
            <div className="aboutCapWrap w-full">
              <div className="aboutCap relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden z-[1] lg:py-12 xl:pt-[4.6875rem] xl:pb-[4.375rem] p-5 md:p-10 lg:px-[3.5rem] xl:px-20">
                <div
                  className=""
                  style={{ backgroundImage: `url(${PatternImg2})` }}
                ></div>

                <Carousel8></Carousel8>
              </div>
            </div>

            <div>
              <div className="aboutCapWrap w-full">
                <div className="aboutCap relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden z-[1] lg:py-12 xl:pt-[4.6875rem] xl:pb-[4.375rem] p-5 md:p-10 lg:px-[3.5rem] xl:px-20 text-center">
                  <div
                    className="aboutCapBg bg-white opacity-90 opc8 absolute rounded-in sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 bg-no-repeat bg-center bg-cover z-[-1]"
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>

                  <ul className="grid grid-cols-1 gap-5 mt-6">
                    <li className="grid grid-cols-3 gap-4 text-[1rem] md:text-[1.125rem]">
                      <p className="leading-relaxed text-desc2 font-semibold font-NunitoSans col-span-4 text-justify">
                        Music has been an integral part of my life from a very
                        young age. Growing up in a household filled with
                        melodies, my father, Mirza Golam Rasul, a renowned music
                        teacher at Khulna Shilpa Kola Academy and founder of his
                        own Music Academy Sa-re-ga-ma-pa, was my first mentor.
                        He instilled in me a deep love and respect for music,
                        particularly Bengali folk music. My journey began as I
                        listened to and learned from the lessons he gave his
                        students, absorbing the meanings and nuances of the
                        lyrics and music. Music runs in my family. I used to
                        wake up with the melody of jingles, as my father Mirza
                        Golam Rasul, a music-maestro himself, used to give
                        regular music lessons. I even used to accompany him to
                        his music Academy only to listen and observe how the
                        latter's students used to sing. My first attempt towards
                        bringing my passion in front of the nation was by
                        participating in a competition named Jatiyo Shikkka
                        Shoptaho 2001.
                        {isExpanded && (
                          <span>
                            There I performed some patriotic and folk songs solo
                            and even with a troupe. That very moment was the
                            kickstart of my career. I also participated in
                            Channel 9's Power Voice reality show in 2012. Since
                            then, Music Artist Abbas Uddin, Neena Hamid, Farida
                            Parveen, Abdul Jabbar, Mujib Pardesi, Anusa Anadil,
                            Baul Shah Alam, Sarkar Lalon Sai, Hasan Raza, Shah
                            Abdul Karim, Durveen Sai,Radharaman Dutta, Kaushik
                            Hossain Tapas, Razzak Dewan, Vijay Sarkar, I learned
                            music from all these talented artists and their
                            songs influenced me a lot to grow and contribute to
                            the society. I keep folk songs in my heart to share
                            the future artist/musician. Beyond performances and
                            recordings, I am deeply committed to nurturing the
                            next generation of musicians and preserving cultural
                            heritage through educational initiatives. I have
                            conducted workshops and mentorship programs aimed at
                            fostering a deeper appreciation for Bengali folk
                            music among young audiences, ensuring its continuity
                            and relevance in the modern era.These initiatives
                            reflect my dedication to cultural preservation and
                            my belief in the transformative power of music as a
                            tool for education and community engagement.
                          </span>
                        )}
                      </p>
                      {/* <button
                       
                        className="text-blue-500 focus:outline-none text-center"
                      >
                        {isExpanded ? "Hide" : "Read More"}
                      </button> */}
                    </li>
                  </ul>
                  <p className="text-[#284BE5] -z-50">
                    <button
                      onClick={toggleText}
                      className="flex items-center justify-center text-black font-bold text-lg overflow-hidden group border border-sky-500 z-50 text-center"
                    >
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['S'] after:bg-[#284BE5] after:text-white after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:-translate-y-full group-hover:translate-y-full duration-500">
                        R
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['E']  after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:translate-y-full group-hover:-translate-y-full duration-500">
                        E
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['E'] after:bg-[#284BE5] after:text-white after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:-translate-y-full group-hover:translate-y-full duration-500">
                        A
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-[''] after:bg-sky-400 after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:translate-y-full group-hover:-translate-y-full duration-500">
                        D
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['L'] after:bg-[#284BE5] after:text-white after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:-translate-y-full group-hover:translate-y-full duration-500">
                        <p className="text-[#ffffff80]">O</p>
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['E'] after:bg-sky-400 after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:translate-y-full group-hover:-translate-y-full duration-500">
                        M
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['S'] after:bg-[#284BE5] after:text-white after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:-translate-y-full group-hover:translate-y-full duration-500">
                        O
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['S'] after:bg-sky-400 after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:translate-y-full group-hover:-translate-y-full duration-500">
                        R
                      </span>
                      <span className="inline-block bg-sky-900 px-[10px] py-4 after:content-['']after:bg-[#284BE5] after:text-white after:absolute after:inset-0 relative after:flex after:justify-center after:items-center after:-translate-y-full group-hover:translate-y-full duration-500">
                        E
                      </span>
                    </button>
                  </p>
                  <img
                    className="fill-accent h-[6.5rem] mt-[2.375rem]"
                    src={Resmi}
                    alt=""
                  ></img>
                  {/* <Signature className="fill-accent h-[4.5rem] mt-[2.375rem]"></Signature> */}
                  {/* About Cap */}
                </div>
                {/* About Cap Wrap */}
              </div>
            </div>
          </div>
          {/* About Us */}
        </div>
      </div>
      {/* About Us Wrap */}
    </section>
  );
};

export default AboutUs;
