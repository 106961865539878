import { ReactComponent as Deezer } from "../lib/icons/deezer.svg";
import { ReactComponent as FacebookIcon } from "../lib/icons/FB.svg";
import { ReactComponent as Gaana } from "../lib/icons/gaana.svg";
import { ReactComponent as InstagramIcon } from "../lib/icons/ig.svg";
import { ReactComponent as Musicapple } from "../lib/icons/musicapple.svg";
import { ReactComponent as YoutubeIcon } from "../lib/icons/Yt.svg";

export const social = [
  {
    socialIcon: (
      <FacebookIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-accent hover:fill-accent"></FacebookIcon>
    ),
    socialLink: "https://www.facebook.com/reshmi.mirza.999",
    socialTitle: "Facebook",
  },
  {
    socialIcon: (
      <InstagramIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></InstagramIcon>
    ),
    socialLink:
      "https://www.instagram.com/reshmipower?igsh=MWI5eGpsZzY3eTdlYg==",
    socialTitle: "Instagram",
  },
  {
    socialIcon: (
      <YoutubeIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#0ddae1] hover:fill-accent"></YoutubeIcon>
    ),
    socialLink: "https://www.youtube.com/@reshmimirza9752",
    socialTitle: "Youtube",
  },
  {
    socialIcon: (
      <Deezer className="h-5 w-5 lg:h-10 lg:w-10 fill-accent hover:fill-accent"></Deezer>
    ),
    socialLink: "https://www.deezer.com/sv/artist/160144082",
    socialTitle: "Deezer",
  },
  {
    socialIcon: (
      <Musicapple className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></Musicapple>
    ),
    socialLink:
      "https://music.apple.com/ca/album/premer-laddu-feat-reshmi-mirza-single/1609317341",
    socialTitle: "Apple Music",
  },
  {
    socialIcon: (
      <Gaana className="h-5 w-5 lg:h-10 lg:w-10 fill-[#0ddae1] hover:fill-accent"></Gaana>
    ),
    socialLink: "https://gaana.com/artist/reshmi-mirza",
    socialTitle: "Gaana",
  },
];
