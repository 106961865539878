import "./styles.css";

import React from "react";
import YoutubeEmbed from './YoutubeEmbed';

export default function Youtube() {
  return (
    <div className="App grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4">
      <YoutubeEmbed embedId="c1u3mpOjQBw?si=g1YMn4uJprqRhzvs&amp;start=376" />
      <YoutubeEmbed embedId="FcsX9j3IuSk?si=urpi0NTWToB6_B-m&amp;start=34" />
      <YoutubeEmbed embedId="RceOPIgNMK4?si=L3-JGyqGzIfCARon&amp;start=52" />
      <YoutubeEmbed embedId="TFKoaQ5-dIo?si=FtgkuKiYkJL3dF8n&amp;start=80" />
      <YoutubeEmbed embedId="W9W1B97uQNs?si=YaD_tAemUSpGUbqr" />
      <YoutubeEmbed embedId="XWanPsKWtDU?si=vM1yzrwbiOML6Pp7&amp;start=281" />
      <YoutubeEmbed embedId="8aeEkNgCDTs?si=WL8-rRutG1dGBL9n&amp;start=48" />
      <YoutubeEmbed embedId="sF6v6olOQzY?si=DhVo78SITCk35L49&amp;start=383" />
      <YoutubeEmbed embedId="jybXwNuAV8I?si=XtMXVNyKjPdtocIO&amp;start=74" />
      <YoutubeEmbed embedId="zBBUfPAFJNk?si=Q3qW_WyeP3Lsm3BM&amp;start=496" />
      <YoutubeEmbed embedId="B202oAvXono?si=H8clCknjX9LhAeFF&amp;start=1035" />
      <YoutubeEmbed embedId="rmY7FaBl-_k?si=QzT4imhCJGLhBr3V&amp;start=2103" />
    </div>
  );
}
